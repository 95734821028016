import React, { useState } from "react";
import { InView } from "react-intersection-observer";


function TeamMobile({ data }) {

    var intersecting = [useState({
        isIntersecting: false,
        setIntersecting: false
    })];

    for (var i = 0; i < data.members.length; ++i) {
        intersecting[i] = ({
            isIntersecting: false,
            setIntersecting: false
        });
    }

    function update(name, key) {

        if (name !== undefined && typeof document !== `undefined`) {

            var elem = document.getElementById(name);

            if (intersecting[key].isIntersecting) {
                setTimeout(function () {
                    elem.classList.add("team-col-hover")
                }, 500);

            } else if (!intersecting[key].isIntersecting) {
                setTimeout(function () {
                    elem.classList.remove("team-col-hover")
                }, -500);
            }
        }
    }

    return (
        <div className="text-center m-auto full-width mt-8">
            <div className="container-margin">
                <p className="uppercase title-white-border" >{data.title}</p>

                <div className="container-grid-team mt-4" id="container-team">
                    {data.membersMobile.map((item, key) => (
                        <InView as="div" className="team-col-mobile" threshold={0.5} key={item.name} onChange={inView => {
                            intersecting[key].isIntersecting = inView
                            intersecting[key].setIntersecting = inView
                            update(item.name, key)
                        }}>
                            <div id={item.name} className="team-col m-auto text-right">
                                <div className="bg-blue-rotate mb-1">
                                    <div className="container-images">
                                        <img src={item.img} alt={item.alt} className="bottom" />
                                        <img src={item.imgHover} alt={item.alt} className="top" />
                                    </div>
                                </div>
                                <div>
                                    <p className="ISemiBold blue uppercase mt-3 info-large">{item.name}</p>
                                    <hr className="half-opacity" />
                                    <p className="ISemiBold white uppercase info-large">{item.job}</p>
                                </div>
                            </div>
                        </InView>
                    ))}
                </div>
            </div>
        </div >
    );
}
export default TeamMobile;